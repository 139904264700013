<template>
  <svg class="divider" viewBox='0 0 100 100' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'><path d='M0,0 C16.6666667,66 33.3333333,98 50,98 C66.6666667,98 83.3333333,66 100,0 L100,100 L0,100 L0,0 Z'/></svg>
</template>

<script>
  export default {
    name: 'Divider'
  }
</script>

<style lang="scss" scoped>
  .divider {
    fill: #F6F6F6;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 10vh;
    z-index: 1;
  }
</style>